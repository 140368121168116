import type { HTMLAttributes } from "react";
import styled from "styled-components";
import "./FormGroup.css";

export const FormGroup = styled(
  ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div
      className={["FormGroup", className].filter((c) => !!c).join(" ")}
      {...props}
    />
  ),
)``;

export default FormGroup;
